//import firebase from "firebase/app";
import firebase from "firebase";
//import "firebase/auth";
//import "firebase/firestore";

const firebaseAuth = firebase.auth;

const firebaseConfig = {
  apiKey: "AIzaSyCtA6f6gbwmcl2h1AcDAl7ax-YcieT5LHQ",
  authDomain: "k-cafe-app.firebaseapp.com",
  databaseURL: "https://k-cafe-app.firebaseio.com",
  projectId: "k-cafe-app",
  storageBucket: "k-cafe-app.appspot.com",
  messagingSenderId: "644372052154",
  appId: "1:644372052154:web:02c48aec060b3d464daa83",
  measurementId: "G-E0EEW4NGCC"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);


// Configure FirebaseUI.
export const firebaseUIConfig = {
  // Popup or rediect signin flow
  signInFlow: 'redirect',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebaseAuth.GoogleAuthProvider.PROVIDER_ID,
    firebaseAuth.FacebookAuthProvider.PROVIDER_ID
  ],
  // Terms of service url/callback.
  tosUrl: 'https://kcafe.app/terms',
  // Privacy policy url/callback.
  privacyPolicyUrl: 'https://kcafe.app/privacy',
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
    /*
    signInSuccessWithAuthResult: (user) => {
      console.log(`[Login] signInSuccessWithAuthResult`, user);
      dispatch(loginSuccess(user));
      //history.push('/');
      return false;
    },
    */
    signInFailure: function(error) {
      // Some unrecoverable error occurred during sign-in.
      // Return a promise when error handling is completed and FirebaseUI
      // will reset, clearing any UI. This commonly occurs for error code
      // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      // occurs. Check below for more details on this.
      console.log(`[Login] signInFailure`, error);
      //dispatch(loginError(error));
    },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('loader').style.display = 'none';
    }
  }
};

export const applyCustomClaims = (user) => {
  if (!user) return Promise.resolve(null);

  return user.getIdToken(true)
  .then((idToken) => {
    const customClaims = getCustomClaims(user, idToken);
    //console.log(`[firebase.applyCustomClaims] customClaims: `, [customClaims, user]);

    user.superAdmin = customClaims.superAdmin;
    user.admin = customClaims.admin;
    user.disabled = customClaims.disabled;
    user.threat = customClaims.threat;
    user.accessLevel = customClaims.accessLevel;

    return Promise.resolve(user);
  })
  .catch((err) => {
    console.error(`[firebase.applyCustomClaims] Error: `, err);
    return Promise.resolve(null);
  });
}

export const getCustomClaims = (user, idToken) => {
  const customClaims = JSON.parse(b64DecodeUnicode(idToken.split('.')[1]));
  const superAdmin = !!customClaims && !!customClaims.superAdmin;
  const admin = !!customClaims && !!customClaims.admin;
  const disabled = !!customClaims && !!customClaims.disabled;
  const threat = !!customClaims && !!customClaims.threat;
  const accessLevel = !!customClaims ? (customClaims.accessLevel || 0) : 0;

  return {
    superAdmin,
    admin,
    disabled,
    threat,
    accessLevel,
  };
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export const b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

//const baseDb = firebaseApp.firestore();
//export const db = baseDb;
