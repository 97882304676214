import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Avatar,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebaseApp, firebaseUIConfig } from "../../components/firebase/firebase";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(./images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    margin: 20,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  logoImage: {
    margin: theme.spacing(1),
    height: 100
  }
}));

const Login = ({ dispatch, history, isLoggingIn, isAuthenticated}) => {
  const classes = useStyles();

  //console.log(`[Login] isLoggingIn: ${isLoggingIn} | isAuthenticated: ${isAuthenticated}`);

  const handleBack = () => {
    history.goBack();
  };

  if (isAuthenticated) {
     return <Redirect to="/" />;
  } else {
    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteContainer}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography
                  className={classes.quoteText}
                  variant="h1"
                >
                  K Cafe: A new experience.
                </Typography>
                <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant="body1"
                  >
                    Coming Soon.
                  </Typography>
                  <Typography
                    className={classes.bio}
                    variant="body2"
                  >
                    Sign up to join our beta.
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <Container component="main" maxWidth="xs">
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <img
                    alt="Logo"
                    className={classes.logoImage}
                    src="./images/logos/logo.png"
                  />
                  <Typography component="h1" variant="h5">
                    SIGN IN
                  </Typography>
                </Paper>
                <Paper className={classes.paper}>
                  <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={firebaseApp.auth()}/>
                </Paper>
              </Container>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
};

Login.propTypes = {
  history: PropTypes.object
};

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
//    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default withRouter(connect(mapStateToProps)(Login));
